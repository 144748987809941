











































































































import Vue from "vue";
import { AppTableData } from "@/components/AppTable.vue";
import {
  AcLinePktFilterEntity,
  PacketFilterEntity,
  VnConnectPktFilterEntity,
} from "@/apis/PacketFilteringApi";
import { ContractListEntity } from "@/apis/ContractLineApi";
import PacketFilteringReference from "@/modals/packetFiltering/PacketFilteringReference.vue";
import PacketFilteringContractReference from "@/modals/packetFiltering/PacketFilteringContractReference.vue";

export default Vue.extend({
  name: "PacketFilteringList",
  data() {
    return {
      downloadUrl: this.$api.packetFilter.getCsvURL(),
      vnConMenuItems: vnConMenuItems,
      acLineMenuItems: acLineMenuItems,
      statusItems: statusItems,
      vnConnect: {
        fields: [
          {
            key: "wnumber",
            label: "VNコネクト番号",
            sortable: true,
          },
          {
            key: "vnConnectName",
            label: "VNコネクト名",
            sortable: true,
          },
          {
            key: "enumberAct",
            label: "回線番号(E番号)",
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (
              _v: string,
              _k: string,
              item: VnConnectPktFilterEntity
            ) => {
              return item.menu === "PF_ACCESS_GW2" && item.enumberSby
                ? `${item.enumberAct}/${item.enumberSby}`
                : item.enumberAct;
            },
          },
          {
            key: "menu",
            label: "メニュー",
          },
          {
            key: "vlan",
            label: "VLAN",
            tdClass: "text-right",
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (
              _v: string,
              _k: string,
              item: VnConnectPktFilterEntity
            ) => {
              if (item.vlanIdType)
                return item.vlanIdType === "SPECIFIED"
                  ? `${item.vlanId}`
                  : this.$filter.enumTo(item.vlanIdType, "vlanIdType");
            },
          },
          {
            key: "dstVpnVnCode",
            label: "接続先VPN/VNコード",
            sortable: true,
            filterByFormatted: true,
            formatter: (
              _v: string,
              _k: string,
              item: VnConnectPktFilterEntity
            ) => {
              return item.vnName
                ? `${item.vnName}(${item.dstVpnVnCode})`
                : item.dstVpnVnCode;
            },
          },
          {
            key: "siteName",
            label: "サイト名",
            sortable: true,
          },
          {
            key: "filterringSettingStatus",
            label: "パケットフィルタリング設定有無",
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: false,
            formatter: (value: string) =>
              this.$filter.enumTo(value, "filterringSettingStatus"),
          },
        ],
        items: [],
        search: {
          wnumber: { type: "text", label: "VNコネクト番号" },
          vnConnectName: { type: "text", label: "VNコネクト名" },
          enumberAct: { type: "text", label: "回線番号(E番号)" },
          menu: { type: "select", label: "メニュー", items: vnConMenuItems },
          vlan: { type: "text", label: "VLAN" },
          dstVpnVnCode: { type: "text", label: "接続先VPN/VNコード" },
          siteName: { type: "text", label: "サイト名" },
          filterringSettingStatus: {
            type: "select",
            label: "パケットフィルタリング設定有無",
            items: statusItems,
          },
        },
        filter: {} as Record<string, unknown>,
        filter_option: {
          wnumber: "like",
          vnConnectName: "like",
          enumberAct: "like",
          menu: "equal",
          vlan: "like",
          dstVpnVnCode: "like",
          siteName: "like",
          filterringSettingStatus: "equal",
        },
      } as Omit<AppTableData<VnConnectPktFilterEntity>, "selected">,
      acLine: {
        fields: [
          {
            key: "enumber",
            label: "回線番号(E番号)",
            sortable: true,
          },
          {
            key: "menu",
            label: "メニュー",
          },
          {
            key: "filterringSettingStatus",
            label: "パケットフィルタリング設定有無",
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: false,
            formatter: (value: string) =>
              this.$filter.enumTo(value, "filterringSettingStatus"),
          },
        ],
        items: [],
        selected: [],
        search: {
          enumber: { type: "text", label: "回線番号(E番号)" },
          menu: { type: "select", label: "メニュー", items: acLineMenuItems },
          filterringSettingStatus: {
            type: "select",
            label: "パケットフィルタリング有無",
            items: statusItems,
          },
        },
        filter: {} as Record<string, unknown>,
        filter_option: {
          enumber: "like",
          menu: "equal",
          filterringSettingStatus: "equal",
        },
      } as Omit<AppTableData<AcLinePktFilterEntity>, "selected">,
      vnConnectPackets: {
        items: [] as PacketFilterEntity[],
      },
      acLinePackets: {
        items: [] as PacketFilterEntity[],
      },
      vnConnectPacketFilterKeyId: "",
      accessLinePacketFilterKeyId: "",
      isLoaded: false,
    };
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  computed: {
    acLineMenuFound(): boolean {
      // 表示:契約回線に「エクステンドイーサ/IPsecGW/プラットフォームGW/リモートアクセスGW」がある場合
      // 非表示：契約回線に「エクステンドイーサ/IPsecGW/プラットフォームGW/リモートアクセスGW」がない場合
      return this.$store.state.user.contractInfo.contractList.some(
        (v: ContractListEntity) => {
          switch (v.accessType) {
            case "EXTEND_ETHERNET":
            case "IPSEC":
            case "PLATFORM_GATEWAY":
            case "REMOTE_ACCESS_GW_CPA":
            case "REMOTE_ACCESS_GW_CRG":
            case "REMOTE_ACCESS_GW_FRE":
              return true;
            default:
              return false;
          }
        }
      );
    },
  },
  methods: {
    async load() {
      await this.loadVnCon();
      if (this.acLineMenuFound) {
        await this.loadAcLine();
      }
    },
    /** VNコネクト一覧更新 */
    async loadVnCon() {
      /** パケットフィルタ用VNコネクト一覧を取得 */
      const vnConnectData = await this.$api.packetFilter.getVnConnects();
      this.vnConnect.items = vnConnectData.packetFilterList;
      this.vnConnectPacketFilterKeyId =
        vnConnectData.vnConnectPacketFilterKeyId;
    },
    /** AC回線一覧更新 */
    async loadAcLine() {
      const acLineData = await this.$api.packetFilter.getAcLines();
      this.acLine.items = acLineData.packetFilterList;
      this.accessLinePacketFilterKeyId = acLineData.accessLinePacketFilterKeyId;
    },
    // パケットフィルタリング設定情報表示（VNコネクト）
    async showVnConDetails(item: VnConnectPktFilterEntity) {
      await this.loadVnConDetails(item);
      await this.$modal.show(PacketFilteringReference, {
        packetFilteringItem: this.vnConnectPackets.items,
        clickRowVnConItem: item,
        packetFilterKeyId: this.vnConnectPacketFilterKeyId,
      });
      await this.load();
    },
    async loadVnConDetails(item: VnConnectPktFilterEntity) {
      // wnumberに対してパケットフィルタリングルール一覧情報取得
      const data = await this.$api.packetFilter.getVnConnectPktFilter(
        item.wnumber
      );
      this.vnConnectPackets.items = data.packetFilterRuleList;
    },
    // パケットフィルタリング設定情報表示（アクセス回線）
    async showAcLineDetails(item: AcLinePktFilterEntity) {
      await this.loadAcLineDetails(item);
      await this.$modal.show(PacketFilteringReference, {
        packetFilteringItem: this.acLinePackets.items,
        clickRowAcLineItem: item,
        packetFilterKeyId: this.accessLinePacketFilterKeyId,
      });
      await this.load();
    },
    async loadAcLineDetails(item: AcLinePktFilterEntity) {
      // enumberに対してパケットフィルタリング一覧情報取得
      const data = await this.$api.packetFilter.getAcLinePktFilter(
        item.enumber
      );
      this.acLinePackets.items = data.packetFilterRuleList;
    },
    async contractModification() {
      await this.$modal.show(PacketFilteringContractReference);
    },
  },
});

/** VNコネクト一覧メニュー */
export const vnConMenuItems = [
  { value: "ETHERNET2", text: "イーサネット方式Ⅱ" },
  { value: "ETHERNET2_PF", text: "イーサネット方式Ⅱ PF" },
  { value: "BROADBAND_ACCESS3", text: "ブロードバンドアクセス方式Ⅲ" },
  { value: "WIRELESS_ACCESS2", text: "ワイヤレスアクセス方式Ⅱ" },
  { value: "PF_ACCESS_GW2", text: "PFアクセスGWⅡ" },
  { value: "ETHERNET_LIGHT", text: "イーサネットライト" },
];

/** アクセス回線一覧メニュー */
export const acLineMenuItems = [
  { value: "ETHERNET", text: "イーサネット方式" },
  { value: "BROADBAND_ACCESS2", text: "ブロードバンドアクセス方式Ⅱ" },
  { value: "WIRELESS_ACCESS", text: "ワイヤレスアクセス方式" },
  { value: "EXTEND_ETHERNET", text: "エクステンドイーサ方式" },
  { value: "IPSEC", text: "IPsec方式" },
  { value: "PLATFORM_GATEWAY", text: "PFGW" },
  { value: "REMORE_ACCESS_GW", text: "リモートアクセスGW" },
];

/** パケットフィルタリング設定有無（検索窓選択肢用） */
export const statusItems = [
  { value: "FILTERING", text: "有り" },
  { value: "NO_FILTERING", text: "無し" },
];
