

































































































































































































































































































































































































import Vue, { PropType } from "vue";
import { CloudVnAwsDetail } from "@/apis/CloudVnAwsApi";
import MultiCloudAwsVnLineEdit from "@/modals/multicloud/aws/MultiCloudAwsVnLineEdit.vue";
import MultiCloudAwsVnConnectionConfirm from "@/modals/multicloud/aws/MultiCloudAwsVnConnectionConfirm.vue";
import { mapState } from "vuex";
import { ContractInfo } from "@/apis/ContractApi";

export default Vue.extend({
  name: "MultiCloudAwsVnConnectionReference",
  props: {
    wnumber: {
      type: String as PropType<string>,
      required: true,
    },
    cloudLineSeq: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      cloudDetail: {} as CloudVnAwsDetail,
      isLoaded: false,
      isChanged: false,
    };
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
  },
  computed: {
    ...mapState("user", {
      contractInfo: "contractInfo",
      contractSummary: "contractSummary",
    }),
    /** 開始日が未来日の場合,true*/
    isFutureDayContract(): boolean {
      return !this.contractInfo.contractList.find(
        (e: ContractInfo["contractList"][0]) =>
          e.enumber === this.cloudDetail.enumber
      );
    },
    /** 品目(帯域)ラベル表示名 */
    bandItemName(): string | null {
      if (
        this.cloudDetail.cloudVnConnectCommon.directConnectType ===
          "HOSTED_VIFS" &&
        this.cloudDetail.cloudVnConnectCommon.location === "EAST_JAPAN"
      ) {
        const bandwidth =
          this.$filter.bandwidthToBps(
            this.cloudDetail.cloudVnConnectCommon.bandwidth
          ) ?? 0;
        // 1Gbps以下の場合、「低帯域」ラベルで表示する
        if (bandwidth <= 1000000000) {
          return "低帯域";
        } else {
          // 上記以外の場合、「広帯域」ラベルで表示する
          return "広帯域";
        }
      }
      return null;
    },
  },
  methods: {
    async load() {
      this.cloudDetail = await this.$api.cloudVnAws.getVnConnect(this.wnumber);
    },
    async showVNConnectDelete() {
      await this.alertClear();
      // 削除確認モーダルの表示
      await this.$modal.show(MultiCloudAwsVnConnectionConfirm, {
        cloudVnAwsDetail: this.cloudDetail,
        displayType: "D",
        bandwidthDisplayName: this.bandItemName,
      });
      // 削除処理
      await this.$api.cloudVnAws.deleteVnConnect(this.cloudLineSeq, {
        cloudVnConnectList: this.cloudDetail.cloudVnConnectSby?.wnumber
          ? [
              this.cloudDetail.cloudVnConnectAct.wnumber,
              this.cloudDetail.cloudVnConnectSby.wnumber,
            ]
          : [this.cloudDetail.cloudVnConnectAct.wnumber],
        cloudVnAwsKeyId: this.cloudDetail.cloudVnAwsKeyId,
      });
      await (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    async showVNConnectModify() {
      await this.alertClear();
      await this.$modal.show(MultiCloudAwsVnLineEdit, {
        cloudLineSeq: this.cloudLineSeq,
        cloudVnAwsDetail: this.cloudDetail,
      });
      await (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    async executed() {
      this.isChanged = true;
      await this.load();
    },
    /** エラーメッセージを初期化する */
    async alertClear() {
      (
        this.$refs.operationCommandAct as Vue & { alertClear: () => void }
      ).alertClear();
      if (this.cloudDetail.cloudVnConnectSby) {
        (
          this.$refs.operationCommandSby as Vue & { alertClear: () => void }
        ).alertClear();
      }
    },
  },
});
