


















































































































import Vue, { PropType } from "vue";
import {
  BandwidthType,
  CreateVNConnectRequest,
  L3Config,
  VNConnect,
  VNConnectDetail,
} from "@/apis/VNConnectApi";
import { VpnVnCode } from "@/apis/InformationApi";
import {
  Type1PrivateInfo,
  Type1PrivateLine,
  Type1PrivateSite,
} from "@/apis/Type1SiteApi";
import {
  AccessLineGetEntity,
  BandwidthListGetEntity,
} from "@/apis/AccessLineApi";
import VNConnectEditStepper1, {
  availablePairLine,
} from "@/modals/vnConnect/VNConnectEditStepper1.vue";
import { NullableProperty } from "@/shims-vue";
import VNConnectEditStepper2 from "@/modals/vnConnect/VNConnectEditStepper2.vue";
import { QosDetail, QosInfo } from "@/apis/QosApi";
import { UpperLimitEntity } from "@/apis/UpperLimitApi";
import VNConnectEditStepper3 from "@/modals/vnConnect/VNConnectEditStepper3.vue";
import cloneDeep from "lodash/cloneDeep";
import { UploadError } from "@/services/ConfigDownloadService";
import BandwidthService from "@/services/BandwidthService";
import { mapState } from "vuex";
import { ContractInfo } from "@/apis/ContractApi";

/** 入力Form */
export type VNConnectForm = NullableProperty<
  Pick<
    VNConnectDetail,
    "vnConnectName" | "pairLine" | "bandwidth" | "qos" | "l2" | "description"
  > & {
    /** サイト */
    siteInfo: null | SiteInfo;
    /** 接続先仮想ネットワーク（VPN/VN） */
    vpnVnCodeInfo: null | VpnVnCode;
    /** 品目（バースト10、バースト100を追加） */
    bandwidthType: BandwidthType;
    /** L3設定 + ルーティング種別 + DHCP ON/OFF */
    l3:
      | (L3Config & {
          routingType: "STATIC" | "BGP4";
          enableDhcpRelay: boolean;
        })
      | null;
  }
>;

/** サイト情報 */
export type SiteInfo = Type1PrivateSite & {
  enumber: Type1PrivateLine["enumber"];
  accessType: Type1PrivateLine["accessType"];
  /** 回線種別によっては契約状況が存在しない */
  lineBandwidth?: BandwidthListGetEntity;
};

export default Vue.extend({
  name: "VNConnectEdit",
  components: {
    VNConnectEditStepper3,
    VNConnectEditStepper2,
    VNConnectEditStepper1,
  },
  props: {
    /** 変更時のみ、必須 */
    wnumber: {
      type: String as PropType<string>,
      required: false,
    },
    connect: {
      type: Object as PropType<VNConnectDetail>,
      required: false,
    },
    /** VNコネクト一覧。追加時のみ必須 */
    vnConnects: {
      type: Array as PropType<VNConnect[]>,
      required: false,
    },
    keyId: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    // L3初期値
    const initL3: VNConnectForm["l3"] = {
      routingType: "STATIC",
      wanAddress: "",
      static: [{ lanAddress: "", nextHopAddress: "" }],
      bgp4: {
        routeAggregation: "OFF",
        routeAggregationAddressList: null,
        peerIpAddress: "",
        asNumber: "",
        md5Key: null,
        maximumPrefix: 100,
        localPreference: "LOW_PRIORITY",
        med: "LOW_PRIORITY",
      },
      enableDhcpRelay: false,
      dhcpRelay: {
        primaryAddress: "",
        secondaryAddress: null,
      },
    };

    let form: VNConnectForm;
    if (this.connect) {
      const detail = cloneDeep(this.connect);
      form = {
        // サイト名と仮想ネットワークはmountedで初期化する
        siteInfo: null,
        vpnVnCodeInfo: null,
        vnConnectName: detail.vnConnectName,
        pairLine: detail.pairLine,
        bandwidthType: detail.bandwidthType,
        // 単位がKbpsの場合のみMbpsに変換する
        bandwidth:
          detail.bandwidth && detail.bandwidth.unit === "KBPS"
            ? this.$service.bandwidth.convertUnit(detail.bandwidth, "MBPS")
            : detail.bandwidth,
        // QoS, L2, L3の設定がなくても全てのUIが動いても大丈夫なように初期化
        qos: detail.qos ?? {
          isQoS: false,
          qosBandControlPattern: null,
        },
        l2: detail.l2 ?? {
          isVlanControl: false,
        },
        l3: detail.l3
          ? {
              ...detail.l3,
              routingType: detail.l3.bgp4 ? "BGP4" : "STATIC",
              ...(detail.l3.bgp4
                ? { static: initL3.static }
                : { bgp4: initL3.bgp4 }),
              // staticが0件だとUI的によくないのでその際は1件追加
              ...(detail.l3.static && detail.l3.static.length === 0
                ? { static: [{ lanAddress: "", nextHopAddress: "" }] }
                : {}),
              enableDhcpRelay: !!detail.l3.dhcpRelay,
              ...(detail.l3.dhcpRelay ? {} : { dhcpRelay: initL3.dhcpRelay }),
            }
          : initL3,
        description: detail.description,
      };
    } else {
      // 新規作成。全てのUIが動いても大丈夫なように初期化
      form = {
        siteInfo: null,
        vpnVnCodeInfo: null,
        vnConnectName: null,
        pairLine: null,
        bandwidthType: null,
        bandwidth: null,
        qos: {
          isQoS: false,
          qosBandControlPattern: null,
        },
        l2: {
          isVlanControl: false,
        },
        l3: initL3,
        description: null,
      };
    }

    return {
      uploadAlert: null as string | null,
      active: 1,
      form,
      vpnVnCode: [] as VpnVnCode[],
      type1Site: {} as Type1PrivateInfo,
      accessLine: {} as AccessLineGetEntity,
      qosDetail: {} as QosDetail,
      qosList: null as QosInfo[] | null,
      // 回線番号未選択または回線の開始日が未来日の場合はundefined
      upperLimitList: undefined as undefined | UpperLimitEntity[],
      isLoaded: false,
    };
  },
  computed: {
    ...mapState("user", {
      contractInfo: "contractInfo",
    }),
    /** true: 編集, false: 追加 */
    isEdit(): boolean {
      return !!this.connect;
    },
    /** サイト一覧 */
    sites(): SiteInfo[] {
      return (
        this.type1Site.lineList
          .flatMap((e1) => {
            return e1.siteList.map((e2) => ({
              enumber: e1.enumber,
              accessType: e1.accessType,
              lineBandwidth: this.accessLine.bandwidthList.find(
                (ac) =>
                  ac.enumberAct === e1.enumber || ac.enumberSby === e1.enumber
              ),
              ...e2,
            }));
          })
          // リモートアクセスGW系はVNコネクトを作成できないので除外
          .filter(
            (e) =>
              e.accessType !== "REMOTE_ACCESS_GW_CPA" &&
              e.accessType !== "REMOTE_ACCESS_GW_CRG" &&
              e.accessType !== "REMOTE_ACCESS_GW_FRE"
          )
      );
    },
  },
  watch: {
    /** 帯域（1G超VNコネクト）とMaximum Prefixで使用するので上限値を取得 */
    async "form.siteInfo.siteId"() {
      if (!this.form.siteInfo) {
        this.upperLimitList = undefined;
        return;
      }

      // 契約に対象の回線情報が存在しない場合はサービス開始日が未来日のため上限値設定情報は取得できない
      if (
        !this.contractInfo.contractList.find(
          (e: ContractInfo["contractList"][0]) =>
            e.enumber === this.form.siteInfo!.enumber
        )
      ) {
        this.upperLimitList = undefined;
        return;
      }

      if (this.form.siteInfo.accessType === "PLATFORM_GATEWAY") {
        // PFGW
        this.upperLimitList = (
          await this.$api.upperLimit.getUpperLimitLine({
            upperLimitLineManageUnit: "PFGW",
            // E番号がSbyの場合があるのでActのE番号を検索
            enumber: (
              await this.$api.upperLimit.getUpperLimitLineSearch("PFGW")
            ).pfgwLineList.find(
              (e) =>
                e.enumberAct === this.form.siteInfo!.enumber ||
                e.enumberSby === this.form.siteInfo!.enumber
            )!.enumberAct,
          })
        ).pfgwLine!.upperLimitList;
      } else {
        // アクセス回線
        this.upperLimitList = (
          await this.$api.upperLimit.getUpperLimitLine({
            upperLimitLineManageUnit: "ACCESS",
            // E番号がSbyの場合があるので契約状況のE番号Actを使用。存在しない場合は選択されているE番号を使用
            enumber:
              this.form.siteInfo.lineBandwidth?.enumberAct ??
              this.form.siteInfo.enumber,
          })
        ).accessLine!.upperLimitList;
      }

      // 特記事項5 - 回線種別が「PFアクセスGWⅡ(QoSパスなし)」の場合、QoS設定が紐づくネットワークは表示しない
      // 上記のためにQoS一覧を取得
      if (
        this.form.siteInfo.accessType === "PF_ACCESS_GW2" &&
        !this.form.siteInfo.lineBandwidth!.isQosPath
      ) {
        this.qosList = await this.$api.qosApi.getQoSInfoList();
      } else {
        this.qosList = null;
      }
    },
  },
  async mounted() {
    // 接続先仮想ネットワーク（VPN/VN）名、サイト一覧から情報情報、アクセス回線一覧取得
    this.vpnVnCode = await this.$api.information.getVpnVnCodeList();
    this.type1Site = await this.$api.type1SiteZone.getType1PrivateList();
    this.accessLine = await this.$api.accessLine.getAll();

    if (this.connect) {
      // 変更の際はサイトと仮想ネットワーク設定
      this.form.siteInfo = this.sites.find(
        (e) =>
          e.enumber === this.connect.enumber &&
          e.vlanIdType === this.connect.vlanIdType &&
          e.vlanId === this.connect.vlanId
      )!;
      this.form.vpnVnCodeInfo = this.vpnVnCode.find(
        (e) => e.vpnVnCode === this.connect.vpnVnCode
      )!;
    }

    this.isLoaded = true;
  },
  methods: {
    /** ステッパー1 → ステッパー2 */
    async nextStepper1(next: () => void) {
      this.uploadAlert = null;
      if (
        this.form.siteInfo!.lineBandwidth &&
        this.form.siteInfo!.lineBandwidth.qos === "ON"
      ) {
        // 契約変更が可能な場合はQoSを取得
        this.qosDetail = await this.$api.qosApi.getQosDetail(
          this.form.vpnVnCodeInfo!.vpnVnCode
        );
      } else {
        // 契約変更が不可な場合はQoSは取得せずQoS未設定扱い
        this.qosDetail = {
          qosType: "NONE",
          priorityTarget: "NONE",
          qosBandwidth: null,
        } as QosDetail;
      }
      next();
    },

    /** 設定ダウンロード処理 */
    downloadConfig() {
      if (this.isEdit) {
        // 変更時
        this.$service.configDownload.downloadVNConnect(
          this.$store.state.user.contractSummary.vnumber,
          undefined,
          this.wnumber,
          "modify",
          this.form
        );
      } else {
        // 追加時
        this.$service.configDownload.downloadVNConnect(
          this.$store.state.user.contractSummary.vnumber,
          this.form.siteInfo?.siteId ?? null,
          undefined,
          "add",
          this.form
        );
      }
    },

    /** 設定ダウンロード処理 */
    async uploadConfig() {
      try {
        const uploadConfig = this.isEdit
          ? await this.$service.configDownload.uploadVNConnect(
              this.$store.state.user.contractSummary.vnumber,
              "modify",
              this.wnumber
            )
          : await this.$service.configDownload.uploadVNConnect(
              this.$store.state.user.contractSummary.vnumber,
              "add",
              undefined
            );
        this.uploadAlert = null;
        await this.$confirm(`VNコネクト設定を上書きします。よろしいですか？`);

        // オブジェクトをnullにされて困るものはifでチェックして上書き処理自体をスキップ
        // nullでも良いものはオプショナルチェーンで設定
        if (!this.isEdit) {
          // 追加の場合のみ変更可能な項目を設定
          if (uploadConfig.siteId) {
            const findSite = this.sites.find(
              (e) => e.siteId === uploadConfig.siteId
            );
            if (findSite) {
              this.form.siteInfo = findSite;
            } else {
              // 該当するサイトが見つからなかった場合は後続の設定は無理なので中断
              this.form.siteInfo = null;
              return;
            }
          }
        }
        // サイトの変更が発生した場合はwatchで各種項目の初期化が走るため次のタイミングで値を設定
        this.$nextTick(() => {
          if (!this.isEdit) {
            if (uploadConfig.vpnVnCode) {
              const findVpnVnCode = this.vpnVnCode.find(
                (e) => e.vpnVnCode === uploadConfig.vpnVnCode
              );
              if (findVpnVnCode) {
                this.form.vpnVnCodeInfo = findVpnVnCode;
              } else {
                // 該当する接続先仮想ネットワークが見つからなかった場合は後続の設定は無理なので中断
                this.form.vpnVnCodeInfo = null;
                return;
              }
            }
            this.form.pairLine = uploadConfig.pairLine ?? null;

            if (uploadConfig.l3) {
              this.form.l3!.wanAddress = uploadConfig.l3.wanAddress ?? "";
              this.form.l3!.routingType =
                uploadConfig.l3.bgp4 === null ? "STATIC" : "BGP4";
            }
          }

          if (uploadConfig.l2) {
            this.form.l2 = uploadConfig.l2;
          }
          if (uploadConfig.l3) {
            if (uploadConfig.l3.static) {
              this.form.l3!.static =
                uploadConfig.l3.static.length === 0
                  ? [{ lanAddress: "", nextHopAddress: "" }]
                  : uploadConfig.l3.static;
            }
            if (uploadConfig.l3.bgp4) {
              this.form.l3!.bgp4 = uploadConfig.l3.bgp4;
            }
          }

          this.form.vnConnectName = uploadConfig.vnConnectName ?? null;
          // 変更且つエクステンドイーサの場合、品目は変更不可
          if (
            !(
              this.isEdit &&
              this.form.siteInfo?.accessType === "EXTEND_ETHERNET"
            )
          ) {
            this.form.bandwidthType = uploadConfig.bandwidthType ?? null;
          }
          this.form.bandwidth = uploadConfig.bandwidth ?? null;
          if (uploadConfig.qos) {
            this.form.qos = uploadConfig.qos;
          }
          if (uploadConfig.l3) {
            this.form.l3!.enableDhcpRelay = uploadConfig.l3.dhcpRelay !== null;
            this.form.l3!.dhcpRelay = this.form.l3!.enableDhcpRelay
              ? uploadConfig.l3.dhcpRelay
              : { primaryAddress: "", secondaryAddress: "" };
          }
          this.form.description = uploadConfig.description ?? null;
        });
      } catch (e) {
        this.uploadAlert = (e as UploadError).message;
      }
    },

    /** 追加・変更 */
    async register() {
      if (this.isEdit) {
        await this.$api.vNConnect.updateVNConnect(
          this.wnumber!,
          formToEntity(this.form, this.qosDetail, this.keyId)
        );
      } else {
        await this.$api.vNConnect.createVNConnect(
          formToEntity(this.form, this.qosDetail, this.keyId)
        );
      }
      (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});

/**
 * Formをリクエスト用の構造に変換
 * @param form Form
 * @param qos QoS情報
 * @param vnConnectKeyId 排他キー
 */
function formToEntity(
  form: VNConnectForm,
  qos: QosDetail,
  vnConnectKeyId: string
): CreateVNConnectRequest {
  return {
    siteId: form.siteInfo!.siteId,
    vpnVnCode: form.vpnVnCodeInfo!.vpnVnCode,
    vnConnectName: form.vnConnectName!,
    // ペア回線表示の場合は設定、それ以外はnull
    pairLine: availablePairLine[form.siteInfo!.accessType]
      ? form.pairLine
      : null,
    bandwidthType: form.bandwidthType!,
    bandwidth: ((): CreateVNConnectRequest["bandwidth"] => {
      switch (form.bandwidthType) {
        case "BANDWIDTH_SECURED":
        case "PF":
        case "TRAFFICFREE":
          if (form.bandwidth!.value === 0.5) {
            // 0.5Mbpsだとバックが受け付けないので500Kbpsに変換
            return new BandwidthService().convertUnit(form.bandwidth, "KBPS");
          } else {
            return form.bandwidth;
          }
        case "BURST":
        case "BESTEFFORT":
        default:
          return null;
      }
    })(),
    qos:
      form.siteInfo!.lineBandwidth?.qos === "ON" && qos.qosType !== "NONE"
        ? {
            ...form.qos!,
            // QoSがOFFの場合は帯域パターンはnull
            qosBandControlPattern: form.qos!.isQoS
              ? form.qos!.qosBandControlPattern
              : null,
          }
        : null,
    l2: form.vpnVnCodeInfo!.vnType === "L2" ? form.l2 : null,
    l3:
      form.vpnVnCodeInfo!.vnType !== "L2"
        ? {
            wanAddress: form.l3!.wanAddress,
            static:
              form.l3!.routingType === "STATIC"
                ? form.l3!.static!.filter((e) => e.lanAddress)
                : null,
            bgp4:
              form.l3!.routingType === "BGP4"
                ? {
                    ...form.l3!.bgp4!,
                    routeAggregationAddressList:
                      form.l3!.bgp4!.routeAggregation === "CUSTOM"
                        ? form.l3!.bgp4!.routeAggregationAddressList
                        : null,
                  }
                : null,
            dhcpRelay: form.l3!.enableDhcpRelay ? form.l3!.dhcpRelay : null,
          }
        : null,
    description: form.description,
    vnConnectKeyId: vnConnectKeyId,
  };
}
