





















































































































































































































































































































































import Vue, { PropType } from "vue";
import { CloudVnOracleDetail } from "@/apis/CloudVnOracleApi";
import { BandWidth } from "@/apis/CloudVnMsApi";

export default Vue.extend({
  name: "MultiCloudOracleVnConnectionConfirm",
  props: {
    cloudVnOracleDetail: {
      type: Object as PropType<CloudVnOracleDetail>,
      required: true,
    },
    // 品目をGbpsからGbpsへ変更した場合に注意文言を表示するため、変更前の品目の値を取得
    beforeBandWidth: {
      type: Object as PropType<BandWidth>,
    },
    /** 画面表示モード：
     * A - 追加確認
     * M - 編集確認
     * D - 削除確認
     */
    displayType: {
      type: String as PropType<"A" | "M" | "D">,
      required: true,
    },
  },
  computed: {
    confirmMessage(): string {
      switch (this.displayType) {
        case "A":
          return "こちらの内容でクラウド接続向けVN回線を追加します。よろしいですか？";
        case "M":
          return "こちらの内容でクラウド接続向けVN回線を変更します。よろしいですか？";
        case "D":
          return "こちらの内容でクラウド接続向けVN回線を削除します。よろしいですか？";
        default:
          throw new Error("wrong displayType!");
      }
    },
    isGbpsChange(): boolean {
      // 追加/削除/Sbyのみ追加時は、チェック不要で非表示
      if (!this.beforeBandWidth) return false;

      const bandwidthRangeBig = [
        { value: 2, unit: "GBPS" },
        { value: 3, unit: "GBPS" },
        { value: 4, unit: "GBPS" },
        { value: 5, unit: "GBPS" },
        { value: 6, unit: "GBPS" },
        { value: 7, unit: "GBPS" },
        { value: 8, unit: "GBPS" },
        { value: 9, unit: "GBPS" },
        { value: 10, unit: "GBPS" },
      ];
      // 変更前の帯域が2GB以上のレンジか否か
      const before = bandwidthRangeBig.some(
        (e) =>
          e.value === this.beforeBandWidth.value &&
          e.unit === this.beforeBandWidth.unit
      );
      // 変更後の帯域が2GB以上のレンジか否か
      const after = bandwidthRangeBig.some(
        (e) =>
          e.value ===
            this.cloudVnOracleDetail.cloudVnConnectCommon.bandwidth.value &&
          e.unit ===
            this.cloudVnOracleDetail.cloudVnConnectCommon.bandwidth.unit
      );
      // レンジが異なる場合のみ警告表示
      return before !== after;
    },
  },
});
