































































































































































































import Vue, { PropType } from "vue";
import { CloudVnMs, CloudVnMsList, PostServiceKey } from "@/apis/CloudVnMsApi";
import { NullableProperty } from "@/shims-vue";
import { BvTableFieldArray } from "bootstrap-vue/src/components/table";
import MultiCloudMsVnConnectionReference from "@/modals/multicloud/ms/MultiCloudMsVnConnectionReference.vue";
import MultiCloudMsVnLineEdit from "@/modals/multicloud/ms/MultiCloudMsVnLineEdit.vue";
import MultiCloudVNConnectStatusModify from "@/modals/multicloud/MultiCloudVNConnectStatusModify.vue";
import { CloudLine, Line } from "@/apis/CloudLineApi";

type CloudVnMsItem = CloudVnMsList["cloudVnConnectsList"][0];

export default Vue.extend({
  name: "MultiCloudMsVnConnectionList",
  props: {
    /** クラウド回線SEQ */
    cloudLineSeq: {
      type: String as PropType<string>,
    },
    /** 該当クラウドメニューの設定未完了VNコネクト有無 */
    hasCloudVnResetting: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    cloudServiceMenu: {
      type: String as PropType<CloudLine["cloudServiceMenu"]>,
    },
    /** アクセス回線メニューの帯域 */
    accessLineMenuBandwidth: {
      type: Object as PropType<Line["bandwidth"]>,
      required: true,
    },
  },
  data() {
    return {
      vnConnectList: {} as CloudVnMsList,
      formServiceKey: {} as NullableProperty<PostServiceKey>,
      locationOptions: [
        { value: "EAST_JAPAN", text: "東日本" },
        { value: "WEST_JAPAN", text: "西日本" },
      ],
      vnConnects: {
        fields: [
          {
            key: "wnumber",
            label: "VNコネクト番号\n(Act/Sby)",
            sortable: true,
            sortByFormatted: true,
            formatter: (_v, _k, item: CloudVnMsItem) => {
              return item.cloudVnConnectAct.wnumber;
            },
          },
          {
            key: "vnConnectName",
            label: "VNコネクト名\n(Act/Sby)",
            sortable: true,
            sortByFormatted: true,
            formatter: (_v, _k, item: CloudVnMsItem) => {
              return item.cloudVnConnectAct.vnConnectName;
            },
          },
          {
            key: "isVnConnectStatus",
            label: "有効/無効\n(Act/Sby)",
            sortable: true,
            sortByFormatted: true,
            formatter: (_v, _k, item: CloudVnMsItem) => {
              return this.$filter.enumTo(
                item.cloudVnConnectAct.isVnConnectStatus,
                "enable"
              );
            },
          },
          {
            key: "vpnVnCode",
            label: "接続先VPN/VNコード",
            sortable: true,
            sortByFormatted: true,
            formatter: (_v, _k, item: CloudVnMsItem) => {
              if (item.cloudVnConnectCommon.vpnVnCode.startsWith("D")) {
                return `${item.cloudVnConnectCommon.vpnVnCode}(
                  ${item.cloudVnConnectCommon.vnType}
                )`;
              }
              return item.cloudVnConnectCommon.vpnVnCode;
            },
          },
          {
            key: "bandwidth",
            label: "品目",
            sortable: true,
            sortByFormatted: true,
            formatter: (_v, _k, item: CloudVnMsItem) => {
              return this.$filter.bandwidthToBps(
                item.cloudVnConnectCommon.bandwidth
              );
            },
          },
          { key: "routingType", label: "ルーティング種別" },
          { key: "description", label: "備考", tdClass: "text-pre-wrap" },
        ] as BvTableFieldArray,
        selected: [] as CloudVnMs[],
      },
      alertMsg: null as string | null,
      /** ポーリング情報. nullの場合はポーリングしてない */
      pooling: null as null | number,
      privatePeeringBandwidth: "" as string,
      msPeeringBandwidth: "" as string,
      isLoaded: false,
    };
  },
  computed: {
    /** true: 一覧選択可能, false: 不可 */
    selectable(): boolean {
      return this.$service.permission.hasAuthority("CONFIG");
    },
    /** true: 回線情報取得の編集不可, false: 編集可能 */
    isDisabledFormServiceKey(): boolean {
      switch (this.vnConnectList.keyStatus) {
        case "ACQUIRING":
        case "SUCCESS":
          return true;
        default:
          return false;
      }
    },
    /** true: VNコネクト追加可能, false: 追加不可 */
    isAddVnConnect(): boolean {
      return this.vnConnectList.cloudVnConnectsList.length <= 1;
    },
  },
  async mounted() {
    await this.load();
    this.isLoaded = true;
    this.privatePeeringBandwidth = this.increaseBandwidth("PRIVATE_PEERING");
    this.msPeeringBandwidth = this.increaseBandwidth("MICROSOFT_PEERING");

    // 取得中状態の場合はポーリングスタート
    if (this.vnConnectList.keyStatus === "ACQUIRING") {
      this.startPooling();
    }
  },
  destroyed() {
    if (this.pooling) {
      clearInterval(this.pooling);
    }
  },
  methods: {
    /** 読み込み */
    async load(loading = true) {
      this.vnConnectList = await this.$api.cloudVnMs.getVnConnects(
        this.cloudLineSeq,
        loading
      );
      this.formServiceKey = {
        serviceKey: this.vnConnectList.serviceKey,
        location: this.vnConnectList.location ?? "EAST_JAPAN",
      };
    },
    /** 取得中状態の間は読み込みを定期的に行うポーリング処理を開始 */
    startPooling() {
      if (this.pooling) {
        clearInterval(this.pooling);
      }
      this.pooling = setInterval(async () => {
        await this.load(false);
        if (this.vnConnectList.keyStatus !== "ACQUIRING" && this.pooling) {
          clearInterval(this.pooling);
        }
      }, 5000);
    },
    /** 増速可能帯域計算 */
    increaseBandwidth(cloudServiceType: string): string {
      const limitBandwidthMbps =
        (this.$filter.bandwidthToBps(this.vnConnectList.bandwidth) ?? 0) /
        1000 /
        1000;
      const targetCloud = this.vnConnectList.cloudVnConnectsList.find(
        (e) => e.cloudVnConnectCommon.cloudServiceType === cloudServiceType
      );
      const targetBandwidthMbps = targetCloud
        ? (this.$filter.bandwidthToBps(
            targetCloud.cloudVnConnectCommon.bandwidth
          ) ?? 0) /
          1000 /
          1000
        : 0;
      // 該当VNコネクトが存在しない場合
      if (targetBandwidthMbps == 0) {
        // 固定文字列「未設定」を返却する
        return "未設定";
      }
      // 該当VNコネクトが存在する場合、増速可能帯域を計算する
      const diffBandwidthMbps = limitBandwidthMbps - targetBandwidthMbps;
      if (diffBandwidthMbps >= 1000) {
        return this.$filter.bandwidthTo({
          value: diffBandwidthMbps / 1000,
          unit: "GBPS",
        });
      } else {
        return this.$filter.bandwidthTo({
          value: diffBandwidthMbps,
          unit: "MBPS",
        });
      }
    },
    /** 回線情報取得 */
    async submit() {
      await this.$api.cloudVnMs.postServiceKey(
        this.cloudLineSeq,
        this.formServiceKey as PostServiceKey
      );
      await this.load();
      this.startPooling();
    },
    /** VNコネクト追加 */
    async register() {
      this.alertMsg = null;
      await this.$modal.show(MultiCloudMsVnLineEdit, {
        cloudVnMsInformation: this.vnConnectList,
        cloudLineSeq: this.cloudLineSeq,
      });
      await (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    /** 有効/無効 */
    async modifyStatus(isEnabled: boolean) {
      if (this.vnConnects.selected.length === 0) {
        this.alertMsg = this.$msg("no_select_data");
        return;
      } else {
        this.alertMsg = null;
      }

      const wNumbers: string[] = await this.$modal.show(
        MultiCloudVNConnectStatusModify,
        {
          enable: isEnabled,
          vnConnects: this.vnConnects.selected,
        }
      );

      if (isEnabled) {
        await this.$api.cloudVnMs.putEnableVnConnect({
          cloudVnConnectList: wNumbers,
          cloudVnMsKeyId: this.vnConnectList.cloudVnMsKeyId,
        });
      } else {
        await this.$api.cloudVnMs.putDisableVnConnect({
          cloudVnConnectList: wNumbers,
          cloudVnMsKeyId: this.vnConnectList.cloudVnMsKeyId,
        });
      }
      await (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
    /** VNコネクト詳細表示 */
    async showConnectionDetails(entity: CloudVnMs) {
      this.alertMsg = null;
      await this.$modal.show(MultiCloudMsVnConnectionReference, {
        wnumber: entity.cloudVnConnectAct.wnumber,
        cloudLineSeq: this.cloudLineSeq,
      });
      await (this.$refs.modal as Vue & { ok: () => void }).ok();
    },
  },
});
